<template>
  <div class="welcome">
      <loading :active.sync="isLoading" 
        :can-cancel="false" 
        :is-full-page="true"></loading>
      <!-- CONTENIDO -->
      <div class="container">
        <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->

        <div class="row top-buffer">
          <div class="col-md-8">
            <ol class="breadcrumb">
              <li>
                <a href="https://www.gob.mx" aria-label="Ir al Portal del Gobierno de México"><i class="icon icon-home" ></i></a>
              </li>
              <li><a href="https://www.gob.mx/sct">SCT</a></li>
              <li><a href="/" >Licencia Federal Digital</a></li>
              <li class="active">Ingresa tus datos</li>
            </ol>
          </div>
        </div>
        <div class="row">
          <div class="col-md-7" id="logotipo">
            <img
              src="../assets/logo.png"
              alt="Secretaría de Comunicaciones y Transportes"
            />
          </div>
        </div>
         <div class="row" style="text-align: end;">
          <a class="one" href="https://ts.sct.gob.mx/VentanillaUnicaS/" target="_blank">Tramita aquí tu Licencia Federal Digital de Conductor</a><br>
          <a class="one" href="https://www.sct.gob.mx/transporte-y-medicina-preventiva/autotransporte-federal/tramites-y-servicios/lfd/" target="_blank">Todo sobre la Licencia Federal Digital de Conductor</a>
        </div>
        <!-- TÍTULO Y SUBTÍTULO -->
        <div class="row">
          <div class="col-md-12">
            <h1>Licencia Federal Digital</h1>
            <h2>Ingresa tus datos</h2>
            <hr class="red" />
          </div>
        </div>
        <!-- CONTENIDO -->
        <div class="row">
          <div class="col-md-12 small-bottom-buffer">
            <p>
              Para descargar la Constancia de expedición de tu Licencia Federal
              Digital, debemos validar los siguientes datos:
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-9">
            <form role="form" class="form-horizontal" @submit.prevent="onSubmit" id="login">
              
              <div class="row form-group">
                <label class="col-md-5 control-label no-margin">
                  CURP<span class="form-text">*</span>:
                </label>
                <div class="col-md-5 ">
                  <input class="form-control"  :class="{'form-control-error': $v.curp.$error }" placeholder="Ingresa tu CURP (18 dígitos)"
                    type="text"
                    id="curp"
                    v-model.trim="$v.curp.$model"
                    name="curp"
                    aria-label="curp"/>
                  <div class="row col-md-10">
                    <small v-if="$v.curp.$error && $v.curp.required" id="error_curp" class="form-text form-text-error">
                      CURP incorrecta
                    </small>
                  </div>
                  <div class="row col-md-10">
                    <small v-if="!$v.curp.required && $v.curp.$error" class="form-text form-text-error">
                      Este campo es obligatorio
                    </small>
                  </div>
                  
                </div>
              </div>
              <div class="row form-group">
                <label class="col-md-5 control-label no-margin">
                  Número teléfonico celular<span class="form-text">*</span>:
                </label>
                <div class="col-md-5">
                  <input class="form-control" :class="{'form-control-error': $v.phone.$error }" placeholder="Ingresa tu número (10 dígitos)"
                    type="text"
                    id="phone"
                    v-model.trim="$v.phone.$model" 
                    name="phone"
                    @keypress="isNumber($event)" 
                    aria-label="Número teléfonico celular"/>
                    <div class="row col-md-10">
                      <small v-if="!$v.phone.required && $v.phone.$error" class="form-text form-text-error">
                        Este campo es obligatorio
                      </small>
                      <small v-if="!$v.phone.maxLength" class="form-text form-text-error">
                        Este campo solo permite 10 dígitos
                      </small>
                      <small v-if="!$v.phone.minLength" class="form-text form-text-error">
                        Este campo debe tener 10 dígitos
                      </small>
                      <small v-if="!$v.phone.numeric" class="form-text form-text-error">
                        Este campo debe ser numerico
                      </small>
                    </div>
                </div>
                
                
              </div>
              <div class="row form-group">
                <label class="col-md-5 control-label no-margin">
                  Número de expediente médico<span class="form-text">*</span>:
                </label>
                <div class="col-md-5">
                  <input class="form-control" :class="{'form-control-error': $v.eMedico.$error}"
                    placeholder="Ingresa tu número de expediente médico"
                    type="text"
                    id="eMedico"
                    v-model.trim="$v.eMedico.$model"
                    name="eMedico"
                    @keypress="isNumber($event)"
                    aria-label="Número de expediente médico"/>
                    <div class="row col-md-10">
                      <small v-if="!$v.eMedico.required && $v.eMedico.$error" class="form-text form-text-error">
                        Este campo es obligatorio
                      </small>
                      <small v-if="!$v.eMedico.numeric" class="form-text form-text-error">
                        Este campo debe ser numerico
                      </small>
                    </div>
                </div>
              </div>
              <div class="row form-group">
                <label class="col-md-5 control-label no-margin">
                  Contraseña de licencia Federal Digital<span class="form-text">*</span>:
                </label>
                <div class="col-md-5">
                  <input class="form-control" :class="{'form-control-error':$v.password.$error}" placeholder="Contraseña" type="password" maxlength="16"
                    id="password"
                    v-model.trim="$v.password.$model"
                    name="password"
                    aria-label="Contraseña" />
                     <small v-if="!$v.password.isPassword" class="form-text form-text-error">
                      Este campo solo permite numeros y letras
                    </small>
                    <small v-if="!$v.password.required && $v.password.$error" class="form-text form-text-error">
                      Este campo es obligatorio
                    </small>
                    <small v-if="!$v.password.maxLength && $v.password.isPassword" class="form-text form-text-error">
                       Este campo solo permite 6 caracteres
                    </small>
                    <small v-if="!$v.password.minLength && $v.password.isPassword" class="form-text form-text-error">
                       Este campo debe tener 6 caracteres
                    </small>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-7 col-md-offset-5">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" aria-label="Acepto"
                      id="terms"
                      v-model.trim="$v.terms.$model"
                      name="terms">
                      He leído y acepto los términos.
                    </label>
                  </div>
                  <small v-if="!$v.terms.accepted && $v.terms.$error" class="form-text form-text-error">
                    Debe aceptar los terminos y condiciones
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5 col-md-offset-5">
                  <p>* Todos los campos son obligatorios</p>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-8 col-md-offset-2">
                  <vue-recaptcha class="pull-right" @verify="onVerify" @expired="onExpired" sitekey="6Lfskm0aAAAAAKotBn7wTkI2BvKUgeIOxl5bLJnb"></vue-recaptcha>
                  <small v-if="!isCaptcha && isError" class="form-text form-text-error col-md-8 col-md-offset-5">
                    Debe verificar captcha.
                  </small>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-10 text-right">
                  <hr />
                  <button class="btn btn-primary pull-right" type="submit">
                    Validar datos
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
       
      </div>
  </div>
</template>
<style >
  #curp{ text-transform: uppercase;}
  a.one:link {color:#0000ff;}
  a.one:visited {color:#0000ff;}
  a.one:hover {color:#ffcc00;}
</style>
<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import router from "../router";
import Swal from "sweetalert2";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import {
  required,
  minLength,
  maxLength,
  numeric,
} from "vuelidate/lib/validators";
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: "welcome",
  components: { Loading,VueRecaptcha },
  data() {
    return {
      isLoading: false,
      errors: [],
      curp: "",
      phone: null,
      eMedico: null,
      password: null,
      terms:false,
      recaptchaToken:'',
      isCaptcha:false,
      isError: false
    };
  },
  validations: {
    terms:{
      accepted: val => val === true,
      required
    },
    curp: {
      required,
      minLength: minLength(18),
      maxLength: maxLength(18),
      isCurp(value) {
        var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
          curp = value.toUpperCase();
        var validado = curp.match(re);
        if (!validado)
          //Coincide con el formato general?
          return false;

        return true;
      },
    },
    phone: {
      required,
      minLength: minLength(10),
      numeric,
      maxLength: maxLength(10),
    },
    eMedico: {
      required,
      numeric
    },
    password: {
      required,
      minLength:minLength(6),
      maxLength:maxLength(6),
      isPassword(password){
        const regex = /^[A-Za-z0-9]*$/
        return regex.test(password);
      },
    },
  },
  methods: {
    onSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "Error";
      } else {
        if(this.isCaptcha){
          this.isLoading = true;
          const vm = this;
          axios.post("api/v1/SignUpPortal/", {
            curp: this.$v.curp.$model,
            telefono: this.$v.phone.$model,
            contrasena: this.$v.password.$model,
            expediente: this.$v.eMedico.$model,
            captcha: this.recaptchaToken
          })
          .then(function (response) {
            vm.isLoading = false;
            localStorage.removeItem("usrData");
            localStorage.setItem(
              "usrData",
              JSON.stringify(response.data.licencia)
            );
            router.replace("/constancia");
          })
          .catch(function (error) {
            console.log(error.response);
            vm.isLoading = false;
            var message = "";
            if (error.response.status === 500) {
              message =
                "Error de servidor favor de contactar a la Secretaría de Comunicaciones y Transportes";
            } else {
              message = "Favor de revisar que todos sus datos sean correctos y vuelva a intentar";
            }
            Swal.fire({
              icon: "error",
              title: "LFD",
              text: message,
            });
          });

        }else{
          this.isError=true;
        }
          
        
        /*
        console.log(usrData);
        store.dispatch({
          type: "login",
          data: usrData,
        });
        */
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onVerify:function(recaptchaToken){
      this.recaptchaToken = recaptchaToken;
      this.isCaptcha = true;
      this.isError = false;
    },
    onExpired: function () {
      this.recaptchaToken = null;
      this.isCaptcha = false;
      this.isError = true;
    },
    
  },
  computed: {
    ...mapState(["licencia"]),
    ...mapActions(["login"]),
  },
};
</script>